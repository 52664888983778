import React from 'react';
import Window from '../../components/common/Window';
import Layout from '../../components/layout/Layout';

import WafaImage from '../../images/wafafullpage.jpg';
import WafaWireframe from '../../images/wireframe.jpg';

import Laravel from '../../images/laravel.png';

export default () => {
  return (
    <Layout pageTitle="El Wafa Wesbite">
      <h1 className="text-center green-text">El Wafa website</h1>
      <p className="text-center">
        A website that exposes El Wafa and Geimexport's products, information
        about the company and contact details for whoever wants to get in touch
      </p>
      <hr className="horizontal-ruler" />
      <Window imageUrl={WafaImage} />
      <p className="text-center">
        <a
          href="http://www.geimexport.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit site <i className="fas fa-external-link-alt" />
        </a>
      </p>
      <h2 className="green-text">Goal</h2>
      <p>
        El Wafa and Geimexport joint trading company reached for me to design,
        develop and deploy a new website for them.
      </p>
      <p>
        After talking to them, I got the idea that they wanted a website that
        included information about:
      </p>
      <ul className="bullet-points">
        <li>The products they sell.</li>
        <li>Contact details and addresses of their outlets.</li>
        <li>General information about the company.</li>
        <li>A admin panel for managin content on the website.</li>
      </ul>
      <h2 className="green-text">Method</h2>
      <div className="row">
        <div className="col-md-7">
          <p>
            After some green tea and brainstorming, I decided that the home page
            should include:
          </p>
          <ul className="bullet-points">
            <li>Full page images on landing.</li>
            <li>A mini 'About us' section.</li>
            <li>A carousel of the latest products added.</li>
            <li>Cards of the outlets that the company operates.</li>
          </ul>
          <p>
            The less demanded and used content like the contact form, extended
            'about us' and all products, all of which were segregated into their
            own page to not clutter the home page with too much information that
            can confuse users.
          </p>
        </div>
        <div className="col-md-5">
          <img
            style={{ maxWidth: '100%', borderRadius: '5px' }}
            src={WafaWireframe}
            alt="wireframe"
          />
        </div>
      </div>
      <h2 className="green-text">Tech</h2>
      <br />
      <ul className="tech-used">
        <li>
          <a
            href="https://laravel.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Laravel} alt="laravel" title="Laravel" />
          </a>
        </li>
        <li>
          <a
            href="https://getbootstrap.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://sdtimes.com/wp-content/uploads/2018/01/bootstrap-stack.png"
              alt="bootstrap"
              title="Bootstrap"
            />
          </a>
        </li>
        <li>
          <a
            href="https://owlcarousel2.github.io/OwlCarousel2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://owlcarousel2.github.io/OwlCarousel2/assets/img/owl-logo.png"
              alt="owl carousel"
              title="Owl Carousel"
            />
          </a>
        </li>
        <li>
          <a
            href="https://webpack.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://proxy.duckduckgo.com/iu/?u=https%3A%2F%2Favatars1.githubusercontent.com%2Fu%2F2105791%3Fv%3D3%26s%3D400&f=1"
              alt="Webpack"
              title="Webpack"
            />
          </a>
        </li>
        <li>
          <a
            href="https://sass-lang.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://proxy.duckduckgo.com/iu/?u=http%3A%2F%2Fmaddesigns.de%2Frwd-sass-compass%2Fimg%2Fsass-logo-new.png&f=1"
              alt="Sass"
              title="Sass"
            />
          </a>
        </li>
      </ul>
    </Layout>
  );
};
