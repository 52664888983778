import React from 'react';
import PropTypes from 'prop-types';

const Window = ({ imageUrl }) => {
  return (
    <div>
      <div className="window-container">
        <div className="window-row">
          <div className="window-col">
            <span className="dot" style={{ background: '#5AC05A' }} />
            <span className="dot" style={{ background: '#FDD800' }} />
            <span className="dot" style={{ background: '#ED594A' }} />
            {/* <span className="close-span" /> */}
          </div>
        </div>

        <div className="window-content">
          <img className="screenshot" src={imageUrl} alt="" />
        </div>
      </div>
    </div>
  );
};

Window.propTypes = {
  imageUrl: PropTypes.string.isRequired
};

export default Window;
